var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('not-linked-indicator'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" QC Documents ")]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":"From Date","type":"date","dense":""},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":"To Date","type":"date","dense":""},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","sm":"12","md":"2"}},[_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":_vm.onFilter}},[_vm._v("Filter")])],1)],1),_c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","placeholder":"Search","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-colsearchColumn',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"12"}},[_c('p',{staticClass:"align-end"},[_vm._v("Last Updated : "+_vm._s(_vm.lastUpdated))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-data-table',{attrs:{"headers":[
                      {
                        text: 'QC Document Number',
                        value: 'qcNumberFormatted',
                        align: 'start',
                        sortable: false,
                      },
                      {
                        text: 'Supplier',
                        value: 'supplierFullDetails',
                        align: 'start',
                        sortable: false,
                      },
                      {
                        text: 'Expected Date',
                        value: 'britishDate',
                        align: 'start',
                        sortable: false,
                      },
                      {
                        text: 'ETA Date / Time',
                        value: 'britishEtaDateTime',
                        align: 'start',
                        sortable: false,
                      },
                      {
                        text: 'Status',
                        value: 'status',
                        align: 'start',
                        sortable: false,
                      },
                      {
                        text: 'Created By',
                        value: 'createdBy',
                        align: 'start',
                        sortable: false,
                      },
                      {
                        text: 'Driver',
                        value: 'driver',
                        align: 'start',
                        sortable: false,
                      },
                      {
                        text: 'Vehicles',
                        value: 'vehicles',
                        align: 'start',
                        sortable: false,
                      },
                      { text: 'Tipping Zone', value: 'intendedTippingZone' },

                      {
                        text: '',
                        value: 'recordActions',
                        align: 'start',
                        sortable: false,
                      },
                      {
                        text: '',
                        value: 'searchColumn',
                        sortable: false,
                        width: '1',
                      } ],"items":_vm.qcDocumentListComputed,"dense":"","search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.searchColumn",fn:function(ref){return undefined}},{key:"item.driver",fn:function(ref){
                    var item = ref.item;
return [(item.driver && item.driver.Forename)?_c('span',[_vm._v(" "+_vm._s(item.driver.Forename)+" "+_vm._s(item.driver.Surname)+" ")]):_vm._e(),(
                          item.driver &&
                          item.driver.Telno !== undefined &&
                          item.driver.Telno !== ''
                        )?_c('span',{staticClass:"my-0 text-center"},[_vm._v("("+_vm._s(item.driver.Telno)+")")]):_vm._e()]}},{key:"item.vehicles",fn:function(ref){
                        var item = ref.item;
return _vm._l((item.vehicles),function(vehicle){return _c('span',{key:vehicle._id},[_vm._v(" "+_vm._s(vehicle.RegNumber)+" "+_vm._s(vehicle.UnitType)+"  ")])})}},{key:"item.intendedTippingZone",fn:function(ref){
                        var value = ref.value;
return [(value && value.code)?_c('span',[_vm._v(" "+_vm._s(value.code)+" "+_vm._s(value.location)+" ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }