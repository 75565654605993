<template>
  <div>
    <v-container fluid>
      <not-linked-indicator />
      <v-row>
        <v-col cols="12" xs="12" md="12">
          <v-card>
            <v-card-title class="primary white--text">
              QC Documents
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row class="mt-2">
                  <v-col cols="5">
                    <v-text-field
                      label="From Date"
                      type="date"
                      v-model="filters.startDate"
                      dense
                    />
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      label="To Date"
                      type="date"
                      v-model="filters.endDate"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="2" class="text-right">
                    <v-btn dark color="primary" @click="onFilter">Filter</v-btn>
                  </v-col>
                </v-row>
                <v-row class="pt-0 mt-0">
                  <v-col cols="12" xs="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-magnify"
                      v-model="search"
                      placeholder="Search"
                      dense
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-colsearchColumn
                    cols="12"
                    xs="12"
                    md="12"
                    class="text-right"
                  >
                    <p class="align-end">Last Updated : {{ lastUpdated }}</p>
                  </v-colsearchColumn>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" md="12">
                    <v-data-table
                      :headers="[
                        {
                          text: 'QC Document Number',
                          value: 'qcNumberFormatted',
                          align: 'start',
                          sortable: false,
                        },
                        {
                          text: 'Supplier',
                          value: 'supplierFullDetails',
                          align: 'start',
                          sortable: false,
                        },
                        {
                          text: 'Expected Date',
                          value: 'britishDate',
                          align: 'start',
                          sortable: false,
                        },
                        {
                          text: 'ETA Date / Time',
                          value: 'britishEtaDateTime',
                          align: 'start',
                          sortable: false,
                        },
                        {
                          text: 'Status',
                          value: 'status',
                          align: 'start',
                          sortable: false,
                        },
                        {
                          text: 'Created By',
                          value: 'createdBy',
                          align: 'start',
                          sortable: false,
                        },
                        {
                          text: 'Driver',
                          value: 'driver',
                          align: 'start',
                          sortable: false,
                        },
                        {
                          text: 'Vehicles',
                          value: 'vehicles',
                          align: 'start',
                          sortable: false,
                        },
                        { text: 'Tipping Zone', value: 'intendedTippingZone' },

                        {
                          text: '',
                          value: 'recordActions',
                          align: 'start',
                          sortable: false,
                        },
                        {
                          text: '',
                          value: 'searchColumn',
                          sortable: false,
                          width: '1',
                        },
                      ]"
                      :items="qcDocumentListComputed"
                      dense
                      :search="search"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:item.searchColumn="{}"> </template>
                      <!-- <template v-slot:item.qcNumberFormatted="{ item }">
                        QC{{item.qcNumber.toString().padStart(6, '0')}}
                      </template> -->

                      <!-- <template v-slot:item.supplier="{ item }">
                        {{ item.supplier.Name }}
                        {{ item.supplier.PostCode }}
                      </template> -->

                      <template v-slot:item.driver="{ item }">
                        <span v-if="item.driver && item.driver.Forename">
                          {{ item.driver.Forename }}&nbsp;{{
                            item.driver.Surname
                          }}
                        </span>
                        <span
                          class="my-0 text-center"
                          v-if="
                            item.driver &&
                            item.driver.Telno !== undefined &&
                            item.driver.Telno !== ''
                          "
                          >({{ item.driver.Telno }})</span
                        >
                      </template>

                      <template v-slot:item.vehicles="{ item }">
                        <span
                          v-for="vehicle in item.vehicles"
                          :key="vehicle._id"
                        >
                          {{ vehicle.RegNumber }}&nbsp;{{
                            vehicle.UnitType
                          }}&nbsp;
                        </span>
                      </template>
                      <template v-slot:item.intendedTippingZone="{ value }">
                        <span v-if="value && value.code">
                          {{ value.code }} {{ value.location }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import QCSystemLinkHelper from "@/classes/QCSystemLinkHelper";
import NotLinkedIndicator from "@/components/SystemNotLinkedIndicator";

export default {
  components: {
    NotLinkedIndicator,
  },

  mounted() {
    this.loadQCDocuments();

    this.timerID = setInterval(() => this.loadQCDocuments(), 120000);
  },

  beforeDestroy() {
    clearInterval(this.timerID);
  },

  computed: {
    qcDocumentListComputed() {
      return this.documents.map((item) => {
        item.britishDate = moment(item.expectedDate).format("DD/MM/YYYY");

        if (item.etaDateTime) {
          item.britishEtaDateTime = moment(item.etaDateTime).format(
            "DD/MM/YYYY @ HH:mm"
          );
        }

        if (item.supplier && item.supplier.Name)
          item.supplierFullDetails = item.supplier.Name + " " + item.supplier.PostCode;

        if (item.driver && item.driver.Forename)
          item.driverFullDetails = item.driver.Forename + " " + item.driver.Surname;

        if(item.vehicles && item.vehicles.length > 0) {
          item.vehicles.forEach(vehicle => {
            item.vehicleFullDetails += vehicle.RegNumber + " " + vehicle.UnitType;
          });
        }

        item.qcNumberFormatted =
          "QC" + item.qcNumber.toString().padStart(6, "0");

        item.searchColumn = JSON.stringify(item);
        return item;
      });
    },
  },

  methods: {
    onFilter() {
      this.loadQCDocuments();
    },

    async loadQCDocuments() {
      let helper = new QCSystemLinkHelper();
      if (!(await helper.isSystemLinked())) {
        return;
      }

      this.$root.showProcessing();

      try {
        let response = await fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + `qcdocuments/list`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.filters),
          }
        );

        let responseJson = await response.json();
        console.log("loadQCDocuments : responseJson", responseJson);
        this.documents = responseJson;
        this.$root.hideProcessing();
        this.lastUpdated = moment().format("DD/MM/YYYY HH:mm");
      } catch (e) {
        this.$root.hideProcessing();
        console.log("loadQCDocuments : error", e.stack || e);
        this.$notify({
          group: "global",
          type: "error",
          title: "Problem Loading QC Documents",
          text: "There was a problem loading the QC Documents. Please try again later.",
        });
      }
    },
  },

  data: () => {
    return {
      filters: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      search: "",
      documents: [],
      timerID: null,
      lastUpdated: null,
    };
  },
};
</script>